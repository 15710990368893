<template>
  <div class="no-permission">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>No Permission</span>
      </div>
      <div class="text-center">
        <el-alert
          title="You don't have the right permission to access this page."
          type="error"
          show-icon
          :closable="false"
        ></el-alert>
        <el-button type="primary" icon="el-icon-back" @click="goBack">
          Go Back
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "NoPermission",
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.no-permission {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  background-color: #f5f5f5;
}

.box-card {
  width: 400px;
}

.text-center {
  text-align: center;
}

.el-alert {
  margin-bottom: 20px;
}
</style>
