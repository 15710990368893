<template>
  <div>
    <div v-if="hasPermission">
      <el-row type="flex" justify="center">
        <el-col :span="12">
          <el-tabs v-model="activeName" @tab-click="tabClicked" stretch>
            <el-tab-pane
              v-for="item in navigationItems"
              :label="item.tabLabel"
              :name="item.tabName"
              :key="item.tabLabel"
            ></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div v-if="activeName == 'activeUsers'">
        <ActiveAgentsPage :isHasPermission="isHasPermission" />
      </div>
      <div v-if="activeName == 'suspendedUsers'">
        <SuspendedAgentsPage />
      </div>
    </div>
    <div v-if="!hasPermission">
      <permissionError />
    </div>
  </div>
</template>

<script>
import ActiveAgentsPage from "./ActiveAgentsPage.vue";
import SuspendedAgentsPage from "./SuspendedAgentsPage.vue";
import permissionError from "../../../../components/PermissionError.vue";
export default {
  name: "SalesAgentsTabs",
  components: {
    ActiveAgentsPage,
    SuspendedAgentsPage,
    permissionError,
  },
  props: {
    providerAreas: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      hasPermission: true,
      activeName: "activeUsers",
      navigationItems: [
        {
          tabLabel: this.$t("SalesAgentsPageTabLabelActiveUsers"),
          tabName: "activeUsers",
        },
        {
          tabLabel: this.$t("SalesAgentsPageTabLabelSuspended"),
          tabName: "suspendedUsers",
        },
      ],
    };
  },
  created() {
    this.chosenTabHandler();
  },
  methods: {
    isHasPermission(hasPermissions) {
      this.hasPermission = hasPermissions;
    },
    chosenTabHandler() {
      const currentSessionTabs = sessionStorage.getItem("sessionTabs");
      if (currentSessionTabs) {
        let parseData = JSON.parse(currentSessionTabs);
        if (parseData.salesAgentsTabs) {
          this.activeName = parseData.salesAgentsTabs;
          return;
        }
      } else {
        const defaultTabName = "activeUsers";
        this.activeName = defaultTabName;
        this.storeActiveTab(defaultTabName);
      }
    },
    tabClicked(eventObject) {
      this.storeActiveTab(eventObject.name);
      if (this.$route.fullPath != "/entities/faturaTeam/salesAgents?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
      }
    },
    storeActiveTab(value) {
      let currentSessionObject = sessionStorage.getItem("sessionTabs");
      let finalValue;
      if (currentSessionObject) {
        let parseData = JSON.parse(currentSessionObject);
        finalValue = {
          ...parseData,
          salesAgentsTabs: value,
        };
      } else {
        finalValue = { salesAgentsTabs: value };
      }
      sessionStorage.setItem("sessionTabs", JSON.stringify(finalValue));
    },
  },
};
</script>

<style></style>
