<template>
  <el-dialog
    :title="$t('ActiveAgentsPageUpdateDialogLabel')"
    :visible.sync="internalFlag"
    @close="closeDialogAction"
    width="50%"
  >
    <el-form :rules="rules" label-position="top" ref="salesAgentDialogRef" :model="dialogFormData">
      <el-row :gutter="15">
        <el-col :lg="12">
          <el-form-item
            label="Agent Name"
            prop="name"
            :label-width="labelWidth"
          >
            <el-input
              size="mini"
              v-model="dialogFormData.name"
              autocomplete="off"
              :disabled="false"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item
            label="Phone Number"
            prop="phone"
            :label-width="labelWidth"
          >
            <el-input
              size="mini"
              v-model="dialogFormData.phone"
              autocomplete="off"
              :disabled="false"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item
            :label="$t('ActiveAgentsPageDialogFieldLabelCode')"
            prop="agentCode"
            :label-width="labelWidth"
          >
            <el-input
              size="mini"
              v-model="dialogFormData.agentCode"
              autocomplete="off"
              :disabled="false"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item
            label="# Of Visits / Day"
            :label-width="labelWidth"
            prop="visitsPerDay"
          >
            <el-input
              size="mini"
              v-model="dialogFormData.visitsPerDay"
              autocomplete="off"
              :disabled="false"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item
            label="wallet limit"
            prop="walletLimit"
            :label-width="labelWidth"
          >
            <el-input
              size="mini"
              v-model="dialogFormData.walletLimit"
              autocomplete="off"
              type="number"
              :disabled="true"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        :label="$t('ActiveAgentsPageDialogFieldLabelAreas')"
        prop="areas"
        :label-width="labelWidth"
      >
        <el-select
          style="width: 100% !important"
          v-model="dialogFormData.areas"
          clearable
          filterable
          multiple
        >
          <el-option
            v-for="item in lookupAreas"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('ActiveAgentsPageDialogFieldLabelUsers')"
        prop="retailers"
        :label-width="labelWidth"
      >
        <el-select
          style="width: 100%"
          v-model="dialogFormData.retailerSearchName"
          filterable
          remote
          reserve-keyword
          :placeholder="
            $t('ActiveAgentsPageDialogFieldValuePlaceholderSearchRetailers')
          "
          :remote-method="remoteMethod"
          :loading="loading"
          @change="retailerClickedInDropdown"
        >
          <el-option
            v-for="item in fetchedRetailers"
            :key="item.id"
            :label="item.storeName"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="``"
        :label-width="labelWidth"
        v-if="chosenUsers.length > 0"
      >
        <div>
          <el-table :data="chosenUsers" border stripe>
            <el-table-column
              prop="id"
              width="100px"
              :label="$t('ActiveAgentsPageTabelColumnUserId')"
            />
            <el-table-column
              prop="storeName"
              :label="$t('ActiveAgentsPageTabelColumnName')"
            >
              <template slot-scope="scope">
                <span>
                  {{ `${scope.row.storeName || scope.row.name}` }}
                </span>
              </template>
            </el-table-column>
            <el-table-column width="100px" label="Unassign">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-close"
                  circle
                  style="color: red"
                  @click="removeRow(scope.row)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelClicked">{{ $t("GlobalsCancelBtn") }}</el-button>
      <el-button type="success" @click="saveClicked">{{
        $t("GlobalsSaveBtn")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { entitiesServices } from "../../../../services/entities.service";
import { faturaTeamService } from "../../../../services/faturaTeam.service.js";

export default {
  name: "AgentUpdateDialog",
  props: {
    editData: {
      required: true,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalFlag: false,
      labelWidth: "160px",
      fetchedRetailers: [],
      loading: false,
      chosenUsers: [],
      rules: {
        name: [
          {
            required: true,
            message: `Agent Name Is required`,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: `Phone Number Is required`,
            trigger: "blur",
          },
        ],
        areas: [
          {
            required: true,
            message: `Areas are required`,
            trigger: "blur",
          },
        ],
        walletLimit: [
          {
            pattern: /^\d+(\.\d{0,2})?$/,
            message: `wallet Limit must be positive number`,
            trigger: "change",
          }
        ],
        visitsPerDay: [
          {
            required: true,
            message: '# Of Visits / Day is required',
            trigger: 'change',
          }
        ],
      },
      defaultCreateData: {
        agentCode: null,
        areas: null,
        retailers: null,
        walletLimit: null,
      },
      dialogFormData: {},
      originalData: null,
    };
  },
  methods: {
    cancelClicked() {
      this.closeDialogAction();
    },
    closeDialogAction() {
      this.$emit("closeDialog");
      this.chosenUsers = [];
      this.dialogFormData = JSON.parse(JSON.stringify(this.defaultCreateData));
    },
    async saveClicked() {
      try {
        let apiObject = {
          agentCode: this.dialogFormData.agentCode,
          areas: this.dialogFormData.areas,
          name: this.dialogFormData.name,
          phone: this.dialogFormData.phone,
          visitsPerDay: this.dialogFormData.visitsPerDay,
        };
        if (this.chosenUsers && this.chosenUsers.length > 0) {
          apiObject.retailers = this.chosenUsers.map((item) => item.id);
        }
        const response = await faturaTeamService.updateAgentProfile(
          this.editData.agentEntityId,
          apiObject
        );
        if (response && response.status == 200) {
          this.popupMessageWrapper(
            this.$t("ActiveAgentsPageDialogUpdateSuccesMessage"),
            "success",
            1500
          );
          setTimeout(() => {
            this.$emit("agentUpdated");
            this.chosenUsers = [];
            this.dialogFormData = JSON.parse(
              JSON.stringify(this.defaultCreateData)
            );
          }, 1000);
        }
      } catch (error) {
        const errorMessage = this.errorMessageExtractor(error);
        this.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    async remoteMethod(query) {
      if (query !== "") {
        const filtersObject = {
          storeName: query,
          activity: ["consumes"],
        };
        if (this.dialogFormData.areas && this.dialogFormData.areas.length > 0) {
          filtersObject.areaIds = this.dialogFormData.areas;
        }
        try {
          this.loading = true;
          const res = await entitiesServices.fetchEntities(1, filtersObject);
          if (res) {
            this.fetchedRetailers = res.data.data.entities;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
        this.loading = false;
      }
    },
    removeRow(clickedRow) {
      const newUsersArray = this.chosenUsers.filter(
        (user) => user.id != clickedRow.id
      );
      this.chosenUsers = newUsersArray;
    },
    retailerClickedInDropdown(data) {
      try {
        const itemAlreadyInserted = this.chosenUsers.filter(
          (item) => item.id === data.id
        );
        if (!(itemAlreadyInserted && itemAlreadyInserted.length > 0)) {
          this.chosenUsers.push(data);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    errorMessageExtractor(errorObject) {
      const errorMessageExistsError =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.error;
      const errorMessageExistsMessage =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.message;
      let finalErrorMessage = this.$t(
        "RetailerWalletsPageWalletDefaultErrorMessage"
      );
      if (errorMessageExistsError) {
        finalErrorMessage = errorObject.response.data.error;
      }
      if (errorMessageExistsMessage) {
        finalErrorMessage = errorObject.response.data.message;
      }
      return finalErrorMessage;
    },
  },
  watch: {
    showDialog(newVal) {
      this.internalFlag = newVal;
      if (newVal === false) {
        this.chosenUsers = [];
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
      }
    },
    editData(newVal) {
      if (newVal) {
        let newFormData = {};
        const areasReformed = newVal.areas.map((item) => {
          return item.id;
        });
        newFormData.name = newVal.name;
        newFormData.phone = newVal.phone;
        newFormData.visitsPerDay = newVal.visitsPerDay;
        newFormData.areas = areasReformed;
        newFormData.agentCode = newVal.agentCode;
        newFormData.retailers = newVal.retailers;
        this.originalData = JSON.parse(JSON.stringify(newFormData));
        this.dialogFormData = newFormData;
        this.chosenUsers = newVal.retailers;
      } else {
        this.dialogFormData = JSON.parse(
          JSON.stringify(this.defaultCreateData)
        );
        this.originalData = null;
      }
    },
  },

  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    lookupAreas() {
      const lookupsObject = this.lookups;
      let areasArray = [];
      if (lookupsObject && lookupsObject.areas) {
        areasArray = JSON.parse(JSON.stringify(lookupsObject.areas));
      }
      return areasArray;
    },
  },
};
</script>

<style></style>
