import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function changeUserProfileStatus(userId, updateBody) {
  return $http.put(`/admin/user-profiles/${userId}`, updateBody, {
    headers: authHeader(),
  });
}
function getSalesAgents(page, filtersObject) {
  let filtersObjectTemp = JSON.parse(JSON.stringify(filtersObject));
  if (
    filtersObjectTemp &&
    filtersObjectTemp.areas &&
    !filtersObjectTemp.areas.length
  ) {
    delete filtersObjectTemp.areas;
  }
  return $http.get(`/admin/agents/acquisition`, {
    params: {
      filter: filtersObjectTemp,
      page,
    },
    headers: authHeader(),
  });
}
function addSalesAgent(requestBody) {
  return $http.post(`/admin/agents/acquisition`, requestBody, {
    headers: authHeader(),
  });
}
function updateAgentProfile(agentId, updateBody) {
  return $http.patch(`/admin/agents/acquisition/${agentId}`, updateBody, {
    headers: authHeader(),
  });
}

function fetchPortalUsers(page = 1, filters) {
  return $http.get(`/admin/user`, {
    params: {
      filter: filters,
      page,
    },
    headers: authHeader(),
  });
}
function addPortalUser(requestBody) {
  return $http.post(`/admin/user`, requestBody, {
    headers: authHeader(),
  });
}
function removeUserRole(profileId, requestBody) {
  return $http.delete(`/admin/user/${profileId}/roles`, {
    data: requestBody,
    headers: authHeader(),
  });
}
function addUserRole(profileId, requestBody) {
  return $http.put(`/admin/user/${profileId}/roles`, requestBody, {
    headers: authHeader(),
  });
}

function importAgentsSheet(toBeSentFormData, action) {
  return $http.post(
    `/admin/agents/acquisition/import?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
      responseType: "arraybuffer",
    }
  );
}
function exportAgentsSheet(action, filters) {
  let filtersObjectTemp = JSON.parse(JSON.stringify(filters));
  if (
    filtersObjectTemp &&
    filtersObjectTemp.areas &&
    !filtersObjectTemp.areas.length
  ) {
    delete filtersObjectTemp.areas;
  }
  return $http.get(`/admin/agents/acquisition/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: { filters: filtersObjectTemp, action },
  });
}

function exportVisitsSheet(action, filters) {
  let filtersObjectTemp = JSON.parse(JSON.stringify(filters));
  if (
    filtersObjectTemp &&
    filtersObjectTemp.areas &&
    !filtersObjectTemp.areas.length
  ) {
    delete filtersObjectTemp.areas;
  }
  return $http.get(`/admin/agents/acquisition/visits/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: { filters: filtersObjectTemp, action },
  });
}
function importVisitsSheet(toBeSentFormData, action) {
  return $http.post(
    `/admin/agents/acquisition/visits/import?action=${action}`,
    toBeSentFormData,
    {
      headers: authHeader(),
    }
  );
}
function unassignedAllVisits(data) {
  return $http.post(`/admin/agents/acquisition/visits/unassign`, data, {
    headers: authHeader(),
  });
}
function downloadUnassignVisits() {
  return $http.get("/admin/agents/acquisition/visits/bulk/unassign/template", {
    responseType: "arraybuffer",
    headers: authHeader(),
  });
}
function uploadUnassignVisits(data) {
  return $http.post("/admin/agents/acquisition/visits/bulk/unassign", data, {
    headers: authHeader(),
  });
}
function downloadTarget(action, filters) {
  return $http.get(`admin/agents/acquisition/targets/export`, {
    params: { action, filters },
    headers: authHeader(),
    responseType: "arraybuffer",
  });
}
function importTargetsSheet(toBeSentFormData) {
  return $http.post(
    `admin/agents/acquisition/targets/import`,
    toBeSentFormData,
    {
      headers: authHeader(),
    }
  );
}
export const faturaTeamService = {
  changeUserProfileStatus,
  getSalesAgents,
  addSalesAgent,
  updateAgentProfile,
  fetchPortalUsers,
  addPortalUser,
  removeUserRole,
  addUserRole,
  importAgentsSheet,
  exportAgentsSheet,
  exportVisitsSheet,
  importVisitsSheet,
  unassignedAllVisits,
  downloadUnassignVisits,
  uploadUnassignVisits,
  downloadTarget,
  importTargetsSheet,
};
