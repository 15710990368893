<template>
  <div>
    <SalesAgentsTabs />
  </div>
</template>

<script>
import SalesAgentsTabs from "./SalesAgentsTabs.vue";
export default {
  name: "SalesAgentsPage",
  components: {
    SalesAgentsTabs,
  },
};
</script>

<style></style>
